import React, { useEffect, useState } from 'react'
import logos from "../assest/logo.png"
import { useDispatch, useSelector } from 'react-redux';
import { fetchLogo } from '../rtk/slices/logo-slice';

function Footer () {
    const dispatch = useDispatch();
    const logo = useSelector((state) => state.logo.logo);

    useEffect(() => {
        // fetch logo
        dispatch(fetchLogo())
    }, [])
    return (
        <section className='footer'>
            <div className="container" data-aos="fade-left" data-aos-duration="1500">
                <div className="footer-top">
                    <img className='logo' src={logo.length > 0 ? `${process.env.REACT_APP_PROJECTS_API}assest/imgs/${logo[0].logo}` : logos} alt="" />
                </div>
                <div className="footer-bottom">
                    <p>©Copyright 2024 <a href="#">Tech Iced</a> </p>
                </div>
            </div>
        </section>
    )
}

export default Footer
