import React, { useEffect, useState } from 'react'
import landing from '../assest/landing.png'
import Aos from 'aos';
import "aos/dist/aos.css"
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function Home () {
    const [home, setHome] = useState([]);
    const { t, i18n } = useTranslation();

    i18next.on('languageChanged', function (lng) {
        // تحديث اتجاه الصفحة بناءً على اللغة الحالية
        document.documentElement.setAttribute('dir', lng === 'ar' ? 'rtl' : 'ltr');
    });

    useEffect(() => {
        Aos.init();

        // fetch home
        const fetchLogo = async () => {
            const res = await fetch(`${process.env.REACT_APP_PROJECTS_API}components/s_d.php?logo`)
            const data = await res.json()
            return setHome(data);
        }
        fetchLogo()
    }, [])
    return (
        <section className='home' id='home'>
            <div className="blur1"></div>
            <div className="blur2"></div>
            <div className="container">
                <div className="social">
                    <ul>
                        <li><a target='_blank' href="https://www.facebook.com/RitalMedia/"><i className="fa fa-facebook-f"></i></a></li>
                        <li><a target='_blank' href="https://api.whatsapp.com/send/?phone=201098221616"><i className="fa fa-whatsapp"></i></a></li>
                    </ul>
                </div>
                <div className="content">
                    <div data-aos="fade-left" data-aos-duration="3000" className="text-area">
                        <div className="text">
                            <h1> <span>{i18n.language == "ar" ? "ريتال ميديا" : "Rital Media"}</span> <br /></h1>
                            <p>{i18n.language == "ar" ? "هل تبحث عن حلول ابداعية في التسويق الرقمي وتصميم المواقع؟ اكتشف ريتال ميديا، الشركاء المثاليون لك في عالم السوشيال ميديا. دعنا نساعدك في بشكل يبهر عملائك ويرفع شأن علامتك التجارية. اختر ريتال ميديا لتجربة فريدة ونجاح يلامس السماء." : "Are you looking for creative solutions in digital marketing and web design Discover retail media, the perfect partners for you in the world of social media. Let us help you impress your customers and raise the profile of your brand. Choose rital media for a unique experience and success that touches the sky."}</p>
                            <div className="btns">
                                <button class="button type1">
                                    <span class="btn-txt"><a href="#services">{i18n.language == "ar" ? "كل الخدمات" : "All Services"}</a></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div data-aos="fade-right" data-aos-duration="3000" className="image-area">
                        <div className="image">
                            <img src={landing} alt="" />
                        </div>
                    </div>
                </div>

                <div className="reviews">
                    <div class="counter">
                        <ul>
                            <li >
                                <div class="single-counter">
                                    <div class="content-review">
                                        <div class="number">
                                            <span className='icon'><svg xmlns="http://www.w3.org/2000/svg" height={"2rem"} viewBox="0 0 512 512"><path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" /></svg></span>
                                        </div>
                                        <p>{i18n.language == "ar" ? "أسرع وقت" : "Fastest Time"}</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="single-counter">
                                    <div class="content-review">
                                        <div class="number">
                                            <span className='icon'><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M290.8 48.6l78.4 29.7L288 109.5 206.8 78.3l78.4-29.7c1.8-.7 3.8-.7 5.7 0zM136 92.5V204.7c-1.3 .4-2.6 .8-3.9 1.3l-96 36.4C14.4 250.6 0 271.5 0 294.7V413.9c0 22.2 13.1 42.3 33.5 51.3l96 42.2c14.4 6.3 30.7 6.3 45.1 0L288 457.5l113.5 49.9c14.4 6.3 30.7 6.3 45.1 0l96-42.2c20.3-8.9 33.5-29.1 33.5-51.3V294.7c0-23.3-14.4-44.1-36.1-52.4l-96-36.4c-1.3-.5-2.6-.9-3.9-1.3V92.5c0-23.3-14.4-44.1-36.1-52.4l-96-36.4c-12.8-4.8-26.9-4.8-39.7 0l-96 36.4C150.4 48.4 136 69.3 136 92.5zM392 210.6l-82.4 31.2V152.6L392 121v89.6zM154.8 250.9l78.4 29.7L152 311.7 70.8 280.6l78.4-29.7c1.8-.7 3.8-.7 5.7 0zm18.8 204.4V354.8L256 323.2v95.9l-82.4 36.2zM421.2 250.9c1.8-.7 3.8-.7 5.7 0l78.4 29.7L424 311.7l-81.2-31.1 78.4-29.7zM523.2 421.2l-77.6 34.1V354.8L528 323.2v90.7c0 3.2-1.9 6-4.8 7.3z"></path></svg></span>
                                        </div>
                                        <p>{i18n.language == "ar" ? "برامج متنوعة" : "Various Programs"}</p>
                                    </div>
                                </div>
                            </li>
                            <li >
                                <div class="single-counter">
                                    <div class="content-review">
                                        <div class="number">
                                            <span className='icon'><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 288 512"><path d="M209.2 233.4l-108-31.6C88.7 198.2 80 186.5 80 173.5c0-16.3 13.2-29.5 29.5-29.5h66.3c12.2 0 24.2 3.7 34.2 10.5 6.1 4.1 14.3 3.1 19.5-2l34.8-34c7.1-6.9 6.1-18.4-1.8-24.5C238 74.8 207.4 64.1 176 64V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48h-2.5C45.8 64-5.4 118.7.5 183.6c4.2 46.1 39.4 83.6 83.8 96.6l102.5 30c12.5 3.7 21.2 15.3 21.2 28.3 0 16.3-13.2 29.5-29.5 29.5h-66.3C100 368 88 364.3 78 357.5c-6.1-4.1-14.3-3.1-19.5 2l-34.8 34c-7.1 6.9-6.1 18.4 1.8 24.5 24.5 19.2 55.1 29.9 86.5 30v48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-48.2c46.6-.9 90.3-28.6 105.7-72.7 21.5-61.6-14.6-124.8-72.5-141.7z" /></svg></span>
                                        </div>
                                        <p>{i18n.language == "ar" ? "اسعار تنافسية" : "Competitive Prices"}</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default Home
